.gallery {
    display: flex;
    flex-wrap: wrap;
    padding-top: 50px;
    justify-content: center;
    @media (max-width: 1200px){
        padding: 20px;
    }
    &-wrapper {
        text-align: center;
        padding: 100px 0;
    }
    
    &-item {
        width: calc(25% - 10px);
        margin: 0 5px;
        img {
            width: 100%;
            height: auto;
        }
    }
    
}

.gallery-slider-wrapper {

    padding-top: 50px;
    
    figure {
       display: flex !important;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 300px;
        @media (max-width: 1200px){
            width: 100%;
            height: 100%;
        }
        
    }
    .slick-track .slick-slide {
        flex-direction: column;
    }
    .gallery-slider-anchor {
        padding: 5px;
        @media (max-width: 1200px){
            width: 100%;
            
            height: 100%;
        }
    }
}
.slick-dots {
	display: flex;
	justify-content: center;
	
	margin: 0;
	padding: 1rem 0;
	
	list-style-type: none;
	
		li {
			margin: 0 0.25rem;
		}
	
		button {
			display: block;
			width: .8rem;
			height: .8rem;
			padding: 0;
			
			border: none;
			border-radius: 100%;
			background-color: #eee;
			
			text-indent: -9999px;
		}
	
		li.slick-active button {
			background-color: $brand-color;
		}
	
}