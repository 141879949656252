.grow { transition: all .2s ease-in-out; }
.grow:hover { transform: scale(1.1); }

.padding-100 {
  padding: 100px 0;
}
.padding-100-all {
  padding: 100px;
  @media (max-width: 1200px) {
    padding: 50px 20px  50px 20px!important;
  }
}
.padding-100-sides {
  padding: 0 100px;
  @media (max-width: 1200px) {
    padding: 50px 20px  50px 20px!important;
  }
}
.center-vertical {
  align-items: center;
}
.flex-center-vertical {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.flex-center-all {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.bg-light-grey {
  background: #f8f8f8;
}
.dark-bg {
  background: #222;
  .heading-main {
    color: #fff;
    .outline-text {
        -webkit-text-stroke-color: white;
    }
  }
  .btn {
    font-weight: 800;
    border-color: #fff;
    color: #fff;
    font-size: 20px;
    margin-bottom: 8px;
  }
  .link--arrowed {
    color: #fff;
  }
}
.grey-bg {
  background: #eee;
}

.content {
  @media (max-width: 1200px) {
    padding: 20px;
  }
}

.content-first {
  @media (max-width: 1200px) {
    padding-top: 50px;
  }
}
.mid-padding {
  @media (max-width: 1500px) and (min-width: 1200px) {
    padding: 0 30px;
  }
}
.mobile-padding-section {
  @media (max-width: 1200px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.section-padding {
  padding: 50px 0;
  @media (max-width: 1200px) {
    padding: 0;
  }
}
.mobile-no-padding-bottom {
  @media (max-width: 1200px) {
    padding-bottom: 0!important;
  }
}

.intro-section {
  display: flex;
  height: 50vh;
  min-height: 500px;
  max-height: 500px;
  position: relative;
  @media (max-width: 1200px){
    flex-direction: column;
    height: auto;
    min-height: inherit;
    max-height: initial;
  }
  &-small {
    @media (max-width: 1200px){
      width: 100%;
      padding: 40px 0;
      text-align: center;
      &-side {
        display: none;
      }
    } 
    width: 40%;
    background: $brand-color;
    position: relative;
    
    &-content {
        display: flex;
        flex-direction:column;
        justify-content: center;
        height: 100%;
        padding-left: 80px;
        padding-right: 140px;
        
        @media (max-width: 1600px){
          padding: 0 20px;
          margin-right: 125px;
        }
        @media (max-width: 1200px){
          margin-right: 0;
        }
        h2 {
          font-size: 36px;
          text-transform: uppercase;
          font-weight: 900;
          line-height: 1;
          color: #fff;
          @media (max-width: 1700px){
            font-size: 30px;  
          }
          @media (max-width: 1400px){
            font-size: 26px;  
          }
        }
      }
    &-side {
      width: 125px;
      height: 100%;
      position: absolute;
      right: 0;
      display: flex;
      top: 0;
      @media (max-width: 1200px){
        display: none;
      }
    }
  }
  &-large {
    width: 100%;
    background: #222;
    display: flex;
    @media (max-width: 1200px){
      flex-direction: column-reverse;
      padding: 40px 0;
    }
    &-text {
      display: flex;
      justify-content: center;
      padding-left: 40px;
      padding-right: 80px;
      width: 66%;
      flex-direction: column;
      @media (max-width: 1200px){
        width: 100%;
        padding: 15px 20px;
        margin-bottom: 0;
      }
      h3 {
        color: #fff;
        font-size: 36px;
        text-transform: uppercase;
        margin-bottom: 30px;
        margin-top: 0;
        font-weight: 800;
      }
      p {
        color: #ddd;
        font-weight: 300;
        line-height: 2;
        margin-top: 0;
      }
      h4 {
        text-transform: uppercase;
        font-size: 16px;
        margin: 4px 0 12px 0;
        color: #8f8f8f;
      }
    }
    &-services {
      width: 33%;
      height: 100%;
      display: flex;
      justify-content: center;
      padding-left: 40px;
      flex-direction: column;
      color: #fff;
      @media (max-width: 1200px){
        width: 100%;
        padding: 15px 20px;
      }
      .learn-more a,
      h3 {
        text-transform: uppercase;
        font-size: 15px;
        margin: 8px 0 28px 0;
        color: #eee;
      }
      
      .learn-more  {
        margin-top: 40px;      
        @media (max-width: 1200px){
          margin-top: 20px;   
        }
        a {
          text-decoration: none;
          color: #eee;
          font-weight: 600;
          margin-bottom: 0;
        }
      }
      ul {
        li {
          text-transform: uppercase;
          font-weight: 800;
          font-size: 20px;
          color: #dddddd;
        }
      }
    }
  }
}
.intro-section {
  &-inner {
    height: 400px;
    min-height: 400px;
    @media (max-width: 1200px){
      height: auto;

    }
  }
  &-large {
    &-text {
      width: 100%;
    }
  }
}
.intro-section-small-side-inner {
  width: 100px;
}

.client-section {
  display: flex;
  height: 400px;
  min-height: 400px;
  position: relative;
  @media (max-width: 1200px){
    flex-direction: column;
    padding: 40px 0;
    height: auto;
    min-height: inherit;
    background: #eee;
  }
  &-small {
    width: 40%;
    position: relative;
    @media (max-width: 1200px){
      width: 100%;
      background: #eee;
    }
    &-content {
        display: flex;
        flex-direction:column;
        justify-content: center;
        height: 100%;
        padding-left: 80px;
        margin-right: 100px;
        background: #222;
        @media (max-width: 1200px){
         background: #eee;
         padding: 20px;
         text-align: center;
         margin: 0;
        
        }
        h2 {
          font-size: 36px;
          text-transform: uppercase;
          font-weight: 900;
          line-height: 1;
          color: #fff;
          margin-top: 0;
          @media (max-width: 1200px){
            color: #222;
          }
        }
      h4 {
        text-transform: uppercase;
        font-size: 15px;
        margin: 4px 0 12px 0;
        color: #8f8f8f;
      }
    }
    &-side {
      width: 100px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background: #eee;
      @media (max-width: 1200px){
        display: none;
      }
    }
  }
  &-large {
    width: 100%;
    background: #eee;
    display: flex;
    justify-content: center;
    align-items: center;
    &-logos {
      display: flex;
      margin: 0 26px;
      justify-content: center;
      align-items: center;
      width: 100%;
      @media (max-width: 1200px){
       flex-wrap: wrap; 
        flex-direction: column;
      }
      .logo {
          flex: 1 0 0;
          width: 33%;
          text-align: center;
          @media (max-width: 1200px){
           width: 100%;
            padding: 20px 0;
          }
       img {
          width: 100%;
          max-width: 240px;
          height: auto;
          @media (max-width: 1200px){
            max-width: 190px;
          }
        }
      }
    }
  }
}
.quote {
  padding: 200px 0 200px 0;
  background: $brand-color;
  @media (max-width: 1200px){
   padding: 120px 0; 
  }
  &-container {
    max-width: 1000px;
    width: 100%;
    position: relative;
    margin: 0 auto;
    @media (max-width: 1400px){
      max-width: 100%;
      padding: 0 100px;
    }
    @media (max-width: 1200px){
     padding: 0 20px; 
    }
    .quote-quotation {
      width: 200px;
      height: 200px;
      position: absolute;
      left: -110px;
      top: -70px;
      @media (max-width: 1400px){
        left: 40px;
      }
      img {
        width: 100%;
        height: auto;
        
      }   
    }
    &-content {
      position: relative;
      z-index: 2;
      p {
        color: #fff;
        font-size: 28px;
        line-height: 1.3;
        font-weight: bold;
      }
      h3, h4 {
        color: #fff;
        line-height: 1;
        margin: 8px 0;
        text-transform: uppercase;
        font-size: 22px;
      }
      h3 {
        font-weight: 300;
        font-style: italic;
      }
      .quote-author {
        float: right;
      }
    }
  }
}
.team-section {
  padding: 150px 0 80px 0;
  position: relative;
  z-index: 2;
  @media (max-width: 1200px){
    padding: 30px 0;
  }
  &:after {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    background-image: url('../images/lapain/logo-faded.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    // background-size: 60% 60%;
    background-position: center center;
    opacity: 1;
    z-index: 0;
    
  }
  .red-heading {
    padding-bottom: 40px;
    position: relative;
    z-index: 2;
  }
  p {
    font-weight: 300;
    line-height: 2;
  }
  .team-members {
    position: relative;
    z-index: 2;
    display: flex;
    @media (max-width: 1200px){
      flex-direction: column;
      justify-content: center;
    }
    &-person {
      padding: 20px 40px;
      width: 50%;
      @media (max-width: 1200px){
        width: 100%;
        padding: 20px;
        max-width: 800px;
        margin: 0 auto;
      }
      h2 {
        font-weight: 800;
      }
      h2,h3 {
        text-align: center;
        text-transform: uppercase;
      }
      h3 {
        color: $dark-grey;
        font-size: 16px;
        margin: 12px 0 20px 0;
      }
      .hex {
        display: inline-block;
        width: 100%;
        position: relative;
        height: 100%;
        -webkit-clip-path: polygon(98.66025% 45%, 99.39693% 46.5798%, 99.84808% 48.26352%, 100% 50%, 99.84808% 51.73648%, 99.39693% 53.4202%, 98.66025% 55%, 78.66025% 89.64102%, 77.66044% 91.06889%, 76.42788% 92.30146%, 75% 93.30127%, 73.4202% 94.03794%, 71.73648% 94.48909%, 70% 94.64102%, 30% 94.64102%, 28.26352% 94.48909%, 26.5798% 94.03794%, 25% 93.30127%, 23.57212% 92.30146%, 22.33956% 91.06889%, 21.33975% 89.64102%, 1.33975% 55%, 0.60307% 53.4202%, 0.15192% 51.73648%, 0% 50%, 0.15192% 48.26352%, 0.60307% 46.5798%, 1.33975% 45%, 21.33975% 10.35898%, 22.33956% 8.93111%, 23.57212% 7.69854%, 25% 6.69873%, 26.5798% 5.96206%, 28.26352% 5.51091%, 30% 5.35898%, 70% 5.35898%, 71.73648% 5.51091%, 73.4202% 5.96206%, 75% 6.69873%, 76.42788% 7.69854%, 77.66044% 8.93111%, 78.66025% 10.35898%);
        clip-path: polygon(98.66025% 45%, 99.39693% 46.5798%, 99.84808% 48.26352%, 100% 50%, 99.84808% 51.73648%, 99.39693% 53.4202%, 98.66025% 55%, 78.66025% 89.64102%, 77.66044% 91.06889%, 76.42788% 92.30146%, 75% 93.30127%, 73.4202% 94.03794%, 71.73648% 94.48909%, 70% 94.64102%, 30% 94.64102%, 28.26352% 94.48909%, 26.5798% 94.03794%, 25% 93.30127%, 23.57212% 92.30146%, 22.33956% 91.06889%, 21.33975% 89.64102%, 1.33975% 55%, 0.60307% 53.4202%, 0.15192% 51.73648%, 0% 50%, 0.15192% 48.26352%, 0.60307% 46.5798%, 1.33975% 45%, 21.33975% 10.35898%, 22.33956% 8.93111%, 23.57212% 7.69854%, 25% 6.69873%, 26.5798% 5.96206%, 28.26352% 5.51091%, 30% 5.35898%, 70% 5.35898%, 71.73648% 5.51091%, 73.4202% 5.96206%, 75% 6.69873%, 76.42788% 7.69854%, 77.66044% 8.93111%, 78.66025% 10.35898%);
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
.inner-companies {
  padding: 180px 0;
  @media (max-width: 1200px){
    padding: 60px 0;
  }
  .company {
    display: flex;
    padding: 80px 0;
    position: relative;
    z-index: 1;
    @media (max-width: 1200px){
      flex-direction: column;
      padding: 40px 0;
    }
    
    &-image {
      width: 33%;
      @media (max-width: 1200px){
        width: 100%;
        text-align: center;
      }
      .hex {
        display: inline-block;
        width: 100%;
        position: relative;
        height: 100%;
        -webkit-clip-path: polygon(98.66025% 45%, 99.39693% 46.5798%, 99.84808% 48.26352%, 100% 50%, 99.84808% 51.73648%, 99.39693% 53.4202%, 98.66025% 55%, 78.66025% 89.64102%, 77.66044% 91.06889%, 76.42788% 92.30146%, 75% 93.30127%, 73.4202% 94.03794%, 71.73648% 94.48909%, 70% 94.64102%, 30% 94.64102%, 28.26352% 94.48909%, 26.5798% 94.03794%, 25% 93.30127%, 23.57212% 92.30146%, 22.33956% 91.06889%, 21.33975% 89.64102%, 1.33975% 55%, 0.60307% 53.4202%, 0.15192% 51.73648%, 0% 50%, 0.15192% 48.26352%, 0.60307% 46.5798%, 1.33975% 45%, 21.33975% 10.35898%, 22.33956% 8.93111%, 23.57212% 7.69854%, 25% 6.69873%, 26.5798% 5.96206%, 28.26352% 5.51091%, 30% 5.35898%, 70% 5.35898%, 71.73648% 5.51091%, 73.4202% 5.96206%, 75% 6.69873%, 76.42788% 7.69854%, 77.66044% 8.93111%, 78.66025% 10.35898%);
        clip-path: polygon(98.66025% 45%, 99.39693% 46.5798%, 99.84808% 48.26352%, 100% 50%, 99.84808% 51.73648%, 99.39693% 53.4202%, 98.66025% 55%, 78.66025% 89.64102%, 77.66044% 91.06889%, 76.42788% 92.30146%, 75% 93.30127%, 73.4202% 94.03794%, 71.73648% 94.48909%, 70% 94.64102%, 30% 94.64102%, 28.26352% 94.48909%, 26.5798% 94.03794%, 25% 93.30127%, 23.57212% 92.30146%, 22.33956% 91.06889%, 21.33975% 89.64102%, 1.33975% 55%, 0.60307% 53.4202%, 0.15192% 51.73648%, 0% 50%, 0.15192% 48.26352%, 0.60307% 46.5798%, 1.33975% 45%, 21.33975% 10.35898%, 22.33956% 8.93111%, 23.57212% 7.69854%, 25% 6.69873%, 26.5798% 5.96206%, 28.26352% 5.51091%, 30% 5.35898%, 70% 5.35898%, 71.73648% 5.51091%, 73.4202% 5.96206%, 75% 6.69873%, 76.42788% 7.69854%, 77.66044% 8.93111%, 78.66025% 10.35898%);
        
        &-logo {
          position: absolute;
          top: 0;
          text-align: center;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          img {
            width: 45%;
          }
        }
        @media (max-width: 1200px){
            width: 80%;
            height: 80%;
            max-width: 500px;
        }
      }
      img {
        width: 100%;
        height: auto;
       
      }
    }
    &-description {
      
      width: 66%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 80px;
      @media (max-width: 1200px){
        width: 100%;
        text-align: center;
        padding: 0;
      }
      
      h2 {
        text-transform: uppercase;
        margin-bottom: 30px;
        font-weight: 800;
      }
      .bold-link {
        margin-top: 40px;
        @media (max-width: 1200px){
          margin-top: 10px;
        }
      }
    }
  }
  .wrapper-top {
    position: relative;
    overflow: hidden;
    &:after {
      content: "";
      width: 50%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: url(../images/backgrounds/faded-worker.png);
      background-repeat: no-repeat;
      background-size: contain;
      @media (max-width: 1200px){
        display: none;
      }
    }
    &:before {
      content: "";
      width: 50%;
      height: 100%;
      position: absolute;
      right: -100px;
      top: 0;
      background: url(../images/backgrounds/faded-hexes-1.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center right;
      @media (max-width: 1200px){
        display: none;
      }
    }
  }
  .wrapper-bottom {
    position: relative;
    overflow: hidden;
    .company {
      flex-direction: row-reverse;
      @media (max-width: 1200px){
        flex-direction: column;
        padding: 40px 0;
      }
      &-description {
        text-align: right;
        @media (max-width: 1200px){
          text-align: center;
        }
      }
      &:last-of-type {
        padding-bottom: 0;
      }
    }
    &:after {
      content: "";
      width: 50%;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background: url(../images/backgrounds/faded-truck.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center right;
      @media (max-width: 1200px){
        display: none;
      }
    }
    &:before {
      content: "";
      width: 50%;
      height: 100%;
      position: absolute;
      left: -12%;
      top: 0;
      background: url(../images/backgrounds/faded-hexes-2.png);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center right;
      @media (max-width: 1200px){
        display: none;
      }
    }
    
  }
}
.site-footer {
  padding: 100px 0 0 0;
  background: #222;
  position: relative;
  overflow: hidden;
  @media (max-width: 1200px){
    text-align: center;
  }
  &:after {
   width: 134%;
    height: 134%;
    content: '';
    position: absolute;
    left: -53%;
    bottom: -41%;
    background-image: url('../images/backgrounds/hurricane-h.svg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    opacity: 0.04;
    
  }
  &-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    @media (max-width: 1400px){
      padding: 0 20px;
    }
    @media (max-width: 1200px){
      flex-direction: column; 
    }
  }
  .footer-left,
  .footer-right {
    width: 50%;
    @media (max-width: 1200px){
      width: 100%;
    }
  }
  .address {
    margin-top: 90px;
    padding: 0 100px;
    color: #ddd;
    
    line-height: 1;
    position: relative;
    z-index: 1;
    p {
      font-weight: 600;
    }
    a {
      color: #ddd;
      text-decoration: none;
    }
    @media (max-width: 1200px){
      padding: 0;
      text-align: center;
    }
  }
  
  h3 {
    text-transform: uppercase;
    font-weight: 800;
    font-size: 28px;
    color: #eee;
  }
  .footer-companies {
    margin-top: 80px;
    padding: 0 100px;
    position: relative;
    z-index: 1;

    @media (max-width: 1200px){
      padding: 0;
      text-align: center;
    }
    h3 {
      font-size: 22px;
    }
    ul {
      li {
        padding: 10px 0;
      }
      a {
        text-decoration: none;
        font-weight: bold;
        font-size: 16px;
        color: #ddd;
        padding: 5px 0;

      }
    }
    
  }
  form {
    overflow: hidden;
    position: relative;
    z-index: 5;
    @media (max-width: 1200px){
      text-align: center;
    }
    label {
      padding-top: 20px;
       display: block;
      font-size: 14px;
      color: #ddd;
      @media (max-width: 1200px){
        text-align: left;
      }
    }
    textarea,
    input {
      border: 0;
      outline: 0;
      background: transparent;
      border-bottom: 1px solid #969696;
      width: 100%;
      margin-bottom: 20px;
      padding: 10px;
      color: #ddd;
    }
    .btn-outline {
      border-color: #ddd;
      border-width:1px;
      color: #ddd;
      font-weight: 600;
      
     }
  }
}
.faded-page-preview-team {
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 60%;
    right: 0;
    top: 0;
    background-image: url('../images/backgrounds/faded-preview-red-faded-truck.png');
    background-size: cover;
   
  }
}
.faded-page-preview-company {
  &:after {
    content: "";
    position: absolute;
    height: 100%;
    width: 60%;
    right: 0;
    top: 0;
    background-image: url('../images/backgrounds/faded-preview-bg.png');
    background-size: cover;
   
  }
}
.faded-page-preview {
  height: 650px;
  width: 100%;
  background: #fff;
  display: flex;
  position: relative;
  @media (max-width: 1200px){
    flex-direction: column;
    justify-content: center;
    height: auto;
    padding: 40px 0;
  }
 
  &-left,
  &-right {
    position: relative;
    z-index: 1;
  }
  &-left {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    padding-right: 100px;
    @media (max-width: 1200px){
      text-align: center;
      width: 100%;
      justify-content: center;
      padding: 20px;
    }
  }
  &-right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 1200px){
      width: 100%;
      justify-content: center;
    }
  }
}
.footer-bottom {
  padding: 100px 0 50px 0;
   width: 100%;
   max-width: 1400px;
   margin: 0 auto;
   position: relative;
   z-index: 1;
    display: flex;
  @media (max-width: 1200px){
    flex-direction: column;
    &-logo {
      text-align :center;
      padding: 10px 0;
    }
  }
  &-copy {
    margin-left: auto;
    text-align: right;
    display: flex;
    align-items: center;
    @media (max-width: 1200px){
      text-align: center;
      margin-left: 0;
      padding: 10px 0 20px 0;
      justify-content: center;
    }
    p {
      color: #eee;
      font-size: 14px;
      line-height: 1.4;
    }
  }
  &-nav {
    margin-left: 140px;
    display: flex;
    align-items: center;
    @media (max-width: 1200px){
      text-align: center;
      padding: 10px 0;
      justify-content: center;
      margin-left: 0;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        display: inline-block;
        a {
          font-weight: 800;
          text-transform: uppercase;
          color: #fff;
          padding: 5px 0;
          margin: 0 15px;
          text-decoration: none;
          position: relative;
        }
       
      }
    }
  }
}

.map {
  width: 100%;
  height: 500px;
}

.workflow {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 50%;
  &-item {
    flex: 1;
    padding: 0 20px;  
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    &:after {
      content: "";
      width: 20px;
      height: 20px;
      bottom: 7px;
      position: absolute;
      right: -10px;
      background-image: url(../images/icons/double-arrow.svg);
    }
    &:last-of-type:after {
      display: none;
    }
    &-icon {
      width: 90px;
      height: 90px;
      border-radius: 45px;
      margin-top: 20px;
      background: #222;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        left: 0;
        background-image: url(../images/icons/excavator-icon-gold.svg);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 50%;
      }
    }
    &-title {
      margin-top: 15px;
      h4 {
        color: $brand-color;
        text-transform: uppercase;
        font-weight: 800;
        font-size: 20px;
        
      }
    }
  }
  &-background {
    background-image: url(../images/backgrounds/faded-workflow.jpg);
    background-size: cover;
    background-position: center center;
  }
}
.icon-list {
  column-count: 2;
  list-style-type: none;
  padding-left: 0;
  margin-top: 50px;
  li {
    
    position: relative;
    margin-bottom: 1.5em;
    padding-left: 3em;

    text-transform: uppercase;
    font-size: 18px;
    color: $brand-color;
    font-weight: 800;
    @media (max-width: 1200px){
      font-size: 16px;
    }
  }
  
  li::before {
    content: '';
    position: absolute;
    width: 2em;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(../images/icons/excavator-icon-dark.svg);
    background-size: 2em 2em;
    background-repeat: no-repeat;
    background-position: center;
  }
}
.services-section {
  padding: 50px 0 40px 0;
  @media (max-width: 1200px) {
    padding: 0;
  }
}
.services-list {
  list-style-type: none;
  padding-left: 0;
  margin-top: 50px;
  li {
    
    position: relative;
    margin-bottom: 1.5em;
    padding-left: 3em;
    text-transform: uppercase;
    font-size: 18px;
    text-decoration: none;
    color: $brand-color;
    font-weight: 800;

    a {
      text-transform: uppercase;
      font-size: 18px;
      text-decoration: none;
      color: $brand-color;
      font-weight: 800;
    }
  }
  
  li::before {
    content: '';
    position: absolute;
    width: 2em;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url(../images/icons/double-arrow.svg);
    background-size: 2em 2em;
    background-repeat: no-repeat;
    background-position: center;
  }
}
// .services-image-second {
//   margin-top: -150px;
//   @media (max-width: 1200px){
//     margin-top: 0px;
//   }
// }
// .hex-grid-services {
//   @media (max-width: 1200px) {
//     margin-top: 20%;
//   }
// }
.image-banner {
  padding: 200px 0;
  position: relative;
  &-inner {
    max-width: 1600px;
    margin: 0 auto;
    width: 100%;
    padding: 0 60px;
    z-index: 2;
    position: relative;
    .learn-more {
      margin-top: 30px;
    }
  }
}

.slick-slider .slick-slide img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.slick-next,
.slick-prev {
  background: none;
  border: 1px solid #fff;
  color: #fff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.slick-next {
  position: absolute;
  bottom: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  z-index: 2;
  
}
.slick-prev {
  position: absolute;
  bottom: 25px;
  right: 85px;
  width: 50px;
  height: 50px;
  z-index: 2;
  img {
    margin-left: 8px;
  }
}

.all-clients {
  background: #eee;
  text-align: center;
  padding: 100px 0;
  h4 {
    color: #222;
  }
  &-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
  }
  &-item {
    width: 25%;
    padding: 30px 15px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1200px){
      width: 50%;
    }
    img {
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      width: 100%;
      max-width: 200px;
    }
  }
}
.hex-wrapper {
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.65));
}
.hex-image-wrapper {
  display: inline-block;
  width: 100%;
  position: relative;
  height: 100%;
  -webkit-clip-path: polygon(98.66025% 45%, 99.39693% 46.5798%, 99.84808% 48.26352%, 100% 50%, 99.84808% 51.73648%, 99.39693% 53.4202%, 98.66025% 55%, 78.66025% 89.64102%, 77.66044% 91.06889%, 76.42788% 92.30146%, 75% 93.30127%, 73.4202% 94.03794%, 71.73648% 94.48909%, 70% 94.64102%, 30% 94.64102%, 28.26352% 94.48909%, 26.5798% 94.03794%, 25% 93.30127%, 23.57212% 92.30146%, 22.33956% 91.06889%, 21.33975% 89.64102%, 1.33975% 55%, 0.60307% 53.4202%, 0.15192% 51.73648%, 0% 50%, 0.15192% 48.26352%, 0.60307% 46.5798%, 1.33975% 45%, 21.33975% 10.35898%, 22.33956% 8.93111%, 23.57212% 7.69854%, 25% 6.69873%, 26.5798% 5.96206%, 28.26352% 5.51091%, 30% 5.35898%, 70% 5.35898%, 71.73648% 5.51091%, 73.4202% 5.96206%, 75% 6.69873%, 76.42788% 7.69854%, 77.66044% 8.93111%, 78.66025% 10.35898%);
  clip-path: polygon(98.66025% 45%, 99.39693% 46.5798%, 99.84808% 48.26352%, 100% 50%, 99.84808% 51.73648%, 99.39693% 53.4202%, 98.66025% 55%, 78.66025% 89.64102%, 77.66044% 91.06889%, 76.42788% 92.30146%, 75% 93.30127%, 73.4202% 94.03794%, 71.73648% 94.48909%, 70% 94.64102%, 30% 94.64102%, 28.26352% 94.48909%, 26.5798% 94.03794%, 25% 93.30127%, 23.57212% 92.30146%, 22.33956% 91.06889%, 21.33975% 89.64102%, 1.33975% 55%, 0.60307% 53.4202%, 0.15192% 51.73648%, 0% 50%, 0.15192% 48.26352%, 0.60307% 46.5798%, 1.33975% 45%, 21.33975% 10.35898%, 22.33956% 8.93111%, 23.57212% 7.69854%, 25% 6.69873%, 26.5798% 5.96206%, 28.26352% 5.51091%, 30% 5.35898%, 70% 5.35898%, 71.73648% 5.51091%, 73.4202% 5.96206%, 75% 6.69873%, 76.42788% 7.69854%, 77.66044% 8.93111%, 78.66025% 10.35898%);
  img {
    width: 100%;
    height: auto;
  }
}
.services-item-hex {
  @media (max-width: 1200px){
    padding: 0 20px;
  }
}
.services-item-content {
  padding: 40px;
  height: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    text-transform: uppercase;
  }
}
.services-maintenance {
  position: relative;
  padding: 100px 0;
  overflow: hidden;
  &:after {
    content: "";
    width: 50%;
    height: 75%;
    position: absolute;
    left: -250px;
    top: 0;
    opacity: 0.5;
    background: url(../images/backgrounds/faded-worker.png);
    background-repeat: no-repeat;
    background-size: contain;
    @media (max-width: 1200px){
      display: none;
    }
  }
  &:before {
    content: "";
    width: 50%;
    height: 75%;
    position: absolute;
    right: -250px;
    bottom: 0;
    background: url(../images/backgrounds/faded-truck.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center right;
    opacity: 0.5;
    @media (max-width: 1200px){
      display: none;
    }
  }
}
.services-excavation {
  position: relative;
  padding: 100px 0;
  overflow: hidden;
  @media (max-width: 1200px){
    padding: 30px 0;
  }
  &:after {
    content: "";
    width: 50%;
    height: 75%;
    position: absolute;
    right: -250px;
    top: 0;
    opacity: 0.5;
    
    background: url(../images/backgrounds/faded-truck.png);
    background-repeat: no-repeat;
    background-size: contain;
    @media (max-width: 1200px){
      display: none;
    }
  }
  &:before {
    content: "";
    width: 50%;
    height: 75%;
    position: absolute;
    left: -250px;
    top: 20%;
    background: url(../images/backgrounds/faded-worker.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center right;
    opacity: 0.5;
    @media (max-width: 1200px){
      display: none;
    }
  }
}
.services-excavation-extra {
  &:before {
    content: "";
    width: 50%;
    height: 75%;
    position: absolute;
    right: -250px;
    bottom: 0;
    opacity: 0.5;
    
    background: url(../images/backgrounds/faded-truck.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
    @media (max-width: 1200px){
      display: none;
    }
  }
}

.cover-image {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.stats {
  padding: 50px 0;
  display: flex;
  @media (max-width: 1200px){
    flex-direction: column;
  }
  &-item {
    width: 33%;
    @media (max-width: 1200px){
      width: 100%;
    }
    &-number {
      font-weight: 800;
      font-size: 58px;
    }
    &-title {
      text-transform: uppercase;
      font-size: 20px;
      margin: 8px 0;
      color: $brand-color;
      font-weight: 900;
      line-height: 1;
      margin-bottom: 0;
    }
  }
}
.certifications {
  display: flex;
  justify-content: center;
  align-items: center;
  // max-width: 80%;
  // margin: 0 auto;
  padding-bottom: 20px;
  @media (max-width: 1200px){
    flex-wrap: wrap;
  }
  &-item {
    padding: 40px;
    width: 25%;
    text-align: center;
    @media (max-width: 1200px){
      width: 50%;
      padding-top: 20px;
    }
    img {
      width: 100%;
      height: auto;
      max-width: 180px;
    }
  }
}

.book-now-form {
  form {
    overflow: hidden;
    position: relative;
    z-index: 5;
    @media (max-width: 1200px){
      text-align: center;
    }
    label {
      padding-top: 20px;
       display: block;
      font-size: 14px;
      color: #222;
      @media (max-width: 1200px){
        text-align: left;
      }
    }
    textarea,
    input {
      border: 0;
      outline: 0;
      background: transparent;
      border-bottom: 1px solid #222;
      width: 100%;
      margin-bottom: 20px;
      padding: 10px;
      color: #222;
    }
    .btn-outline {
      border-color: #222;
      border-width:1px;
      color: #222;
      font-weight: 600;
      
     }
  }
}

.contact-addressses {
  h5 {
    margin-top: 12px;
    a {
      color: #ddd;
      text-decoration: none;
      
      font-size: 18px;
    }
  }
}
.contact-page-form {
  padding: 100px 0;
  
  @media (max-width: 1200px) {
    padding: 100px 30px;
  }
  &-inner {
    max-width: 800px;
  margin: 0 auto;
  h2 {
    text-align: center;
  }
  }
  form {
    overflow: hidden;
    position: relative;
    z-index: 5;
    
    @media (max-width: 1200px){
      text-align: center;
    }
    label {
      padding-top: 20px;
       display: block;
      font-size: 14px;
      color: #222;
      @media (max-width: 1200px){
        text-align: left;
      }
    }
    textarea,
    input {
      border: 0;
      outline: 0;
      background: transparent;
      border-bottom: 1px solid #222;
      width: 100%;
      margin-bottom: 20px;
      padding: 10px;
      color: #222;
    }
    .btn-container {
      text-align: center;
    }
    .btn-outline {
      border-color: #222;
      border-width:1px;
      
      font-weight: 600;
     
      
     }
  }
}
/**************************\
  Basic Modal Styles
\**************************/



.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal__container {
  background-color: #fff;
  padding: 30px;
  max-width: 750px;
  width: 100%;
  max-height: 100vh;
  border-radius: 4px;
  overflow-y: auto;
  box-sizing: border-box;
  z-index: 999;
  .btn-outline:hover {
    color: #fff;
  }
}

.modal__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal__title {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box;
}

.modal__close {
  background: transparent;
  border: 0;
  position: absolute;
  top: 15px;
  right: 15px;
  height: 20px;
  width: 20px;
  font-size: 24px;
}

.modal__header .modal__close:before { content: "\2715"; }

.modal__content {
  margin-top: 2rem;
  margin-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0,0,0,.8);
  .phone {
    color: #222;
    font-weight: 600;
    text-decoration: none;
  }
}

.modal__btn {
  font-size: .875rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  background-color: #e6e6e6;
  color: rgba(0,0,0,.8);
  border-radius: .25rem;
  border-style: none;
  border-width: 0;
  cursor: pointer;
  -webkit-appearance: button;
  text-transform: none;
  overflow: visible;
  line-height: 1.15;
  margin: 0;
  will-change: transform;
  -moz-osx-font-smoothing: grayscale;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  transition: -webkit-transform .25s ease-out;
  transition: transform .25s ease-out;
  transition: transform .25s ease-out,-webkit-transform .25s ease-out;
}

.modal__btn:focus, .modal__btn:hover {
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
}

.modal__btn-primary {
  background-color: $brand-color;
  color: #fff;
}



/**************************\
  Demo Animation Style
\**************************/
@keyframes mmfadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}

@keyframes mmfadeOut {
    from { opacity: 1; }
      to { opacity: 0; }
}

@keyframes mmslideIn {
  from { transform: translateY(15%); }
    to { transform: translateY(0); }
}

@keyframes mmslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut .3s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut .3s cubic-bezier(0, 0, .2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}

.text-link {
  color: #222;
  text-decoration: none;
  font-weight: 600;
}

.slick-wrapper,
.slick-slider {
  height: 100%;
}
.slick-track {
  display: flex;
}
.slick-track .slick-slide {
  display: flex;
  height: auto;
}
.slick-list,
.slick-track {
    height: 100%;
}
.slick-slide {
  height: 100%; 
  width: 100%; 
}
.slick-slide img{
  height: 100%; 
  width: 100%; 
  object-fit: cover;
}