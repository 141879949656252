.hero {
    height: 100vh;
    min-height: 1000px;
    max-height: 1000px;
    width: 100%;
    display: flex;
    flex-direction: row;
    position: relative;
    @media (max-width: 1200px){
      height: auto;
      min-height: inherit;
      max-height: initial;
      padding: 180px 0 100px 0;
      flex-direction: column;
      background: url('../images/lapain/home-hero.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right;
      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        // background: rgba(128, 43, 43, 0.68);
      }
     }
    &-large-hexagon {
      width: 40%;
      height: 100%;
      background: #fff;
      position: relative;
      @media (max-width: 1200px){
        width: 100%;
        background: none;
      }
      &-side {
        width: 250px;
        position: absolute;
        height: 100%;
        top: 0;
        bottom: 0;
        right: -250px;
        background: transparent;
        @media (max-width: 1200px){
          display: none;
        }
        img {
          width: 100%;
          height: 100%;
        }
        
      }
      &-content {
        display: flex;
        flex-direction:column;
        justify-content: center;
        height: 100%;
        padding-left: 80px;
        position: absolute;
        width: calc(100% + 160px);
        z-index: 2;
        @media (max-width: 1600px){
            padding-left: 40px;
        }
        @media (max-width: 1200px){
          position: relative;
          width: 100%;
          padding: 0 20px;
          p,
          h1 {
            color: #fff;
          }
          .outline-text {
            -webkit-text-stroke-color: #fff;
          }
          .btn-outline {
            color:#fff;
            background-color: #222;
          }
        }
        .btn-outline {
          font-weight: 600;
          border: 1px solid #222;
          width: max-content;
        }
        h1 {
          text-transform: uppercase;
          font-weight: 900;
          line-height: .9;
          font-size:70px;
          
          margin: 4px 0;
          // position: absolute;
          // width: 100%;
          @media (max-width: 2000px){
            font-size: 65px;
          } 
          @media (max-width: 1640px){
            font-size: 50px;
          } 
           @media (max-width: 1200px){
            font-size: 80px;
          } 
          @media (max-width: 600px){
            font-size: 60px;
          } 
          .top-text {
            font-size: .65em;
            line-height: 1;
            display: block;
          }
        } 
        p {
          font-weight: 300;
          margin: 4px 0;
          line-height: 1.8;
          
        }
        .mobile-large-heading {
          @media (max-width: 800px){
            font-size: 50px;
          } 
        }
      }
    }
    &-right {
      display: flex;
      align-items: center;
      width: 100%;
      background: url('../images/lapain/home-hero.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: right;
      @media (max-width: 1200px){
       background: none; 
      }
    }
  }
  .hero-companies {
    display: flex;
    flex-direction: column;
    margin-left: 210px;
    position: relative;
    z-index: 2;
    @media (max-width: 1200px){
      margin: 0;
      width: 100%;
      background: #eee;
      background: rgba(255, 255, 255, 0.42);
      flex-direction: row;
      padding: 20px;
      margin-top: 70px;
    }
    &-item {
      width: 250px;
      height: 230px;
      margin-left: 10px;
      margin-right: -50px;
      margin-top: 10px;
      margin-bottom: 10px;
      opacity: 0;
      animation-name: fadeIn;
      animation-duration: 1s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
      position :relative;
      @media (max-width: 1200px){
        width: 100%;
        height: auto;
        margin: 0!important;
        padding: 6px;
      }
      &-image {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        img {
          width: 55%;
          height: auto;
        }
        @media (max-width: 1200px){
         padding: 20px;
          img {
            width: 80%;
            height: auto;
          }
        }
      }
    }
    &-item:nth-child(2) {
      margin-left: 104%;
      margin-top: -62%;
      margin-bottom: 5px;
      animation-delay: 0.25s;
    }
    &-item:nth-child(3) {
      margin-left: 104%;
      margin-bottom: -62%;
      margin-top: 5px;
          animation-delay: .5s;
    }
     &-item:nth-child(4) {
      margin-bottom: 0;
      animation-delay: .75s;
    }
  }
  .hero-inner {
    max-height: 800px;
    min-height: 800px;
    
    @media (max-width: 1200px){
      max-height: inherit;
      min-height: auto;
      padding: 180px 0 100px;
      background: url('../images/lapain/home-hero.jpg');
      background-size: cover;;
      &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.38);
      }
    }
    .hero-right {
      background: url('../images/lapain/home-hero.jpg');
    }
    .hero-large-hexagon-side {
      width: 200px;
      right: -200px;
    }
  }